import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Container, Button, Typography, TextField, Paper, Snackbar, makeStyles } from "@material-ui/core";
import gql from 'graphql-tag';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from "axios";
import { getBaseURL } from "../../src/baseURL";
import { useSelector } from "react-redux";

const GET_COMPANIES = gql`
    query GetCompanies {
        company {
            name
            id
            state
        }
    }
`;

const GET_ACCESS_REQUESTS = gql`
    query GetAccessRequests($id: Int, $state: Boolean) {
        accessRequests(id: $id, state: $state) {
            id
            reasonForRequest
            requestDate
            user {
                email
            }
            requestedCompanies {
                id
                name
                state
            }
        }
    }
`;

const ADD_ACCESS_REQUEST = gql`
    mutation AddAccessRequest($reasonForRequest: String!, $requestedCompanies: [Int]!) {
        addAccessRequest(reasonForRequest: $reasonForRequest, requestedCompanies: $requestedCompanies) {
            id
        }
    }
`;

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    }
}));

export const AccessRequest = ({ email }) => {
    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);

    const config = {
        headers: { 'Authorization': `Bearer ${currentUser.jwtIdToken.idToken}` },
        timeout: 24000000
    };

    const [accessRequests, setAccessRequests] = useState([]);
    const [reasonForRequest, setReasonForRequest] = useState("");
    const [requestedCompanies, setRequestedCompanies] = useState([]);
    const [accessRequestSubmitted, setAccessRequestSubmitted] = useState(false);
    const [missingRequestReason, setMissingRequestReason] = useState(false);
    const [missingCompany, setMissingCompany] = useState('');
    const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    const [errorSnackBarText, setErrorSnackBarText] = useState("");

    const { data: companies } = useQuery(GET_COMPANIES);
    const { refetch } = useQuery(GET_ACCESS_REQUESTS, { onCompleted: data => setAccessRequests(data.accessRequests) });
    const [addAccessRequestMutation] = useMutation(ADD_ACCESS_REQUEST);

    useEffect(() => {
        handleDownloadClick();
    }, []);

    const handleAutoComplete = (event, value) => {
        const selectedCompanyIDs = value.map(company => company.id);
        setRequestedCompanies(selectedCompanyIDs);
        setMissingCompany("");
    };

    const handleAccessRequestSubmit = () => {
        if (!reasonForRequest) {
            setMissingRequestReason(true);
            return;
        }

        if (requestedCompanies.length === 0) {
            setMissingCompany("Please Select Companies");
            return;
        }

        const formData = { reasonForRequest, requestedCompanies };

        // Check if the user has already requested access to these companies
        const userAccessRequests = accessRequests.filter(req => req.user.email === email);
        const alreadyRequested = userAccessRequests.some(req =>
            req.requestedCompanies.some(company => requestedCompanies.includes(company.id))
        );

        if (alreadyRequested) {
            setErrorSnackBarText("You have already requested access for this company");
            setErrorSnackBarOpen(true);
        } else {
            Axios.post(`${getBaseURL()}files/accessManageRequest`, formData, config)
                .then(response => {
                    if (response.status === 200) {
                        setErrorSnackBarText("Access request submitted successfully");
                        setAccessRequestSubmitted(true);
                    } else {
                        handleErrorResponse("Failed to submit access request");
                    }
                })
                .catch(error => handleErrorResponse("An error occurred while submitting the request"));
        }
    };

    const handleErrorResponse = (message) => {
        setErrorSnackBarText(message);
        setErrorSnackBarOpen(true);
        console.error(message);
    };

    const handleDownloadClick = () => {
        Axios.get(`${getBaseURL()}files/getAccessRequest`, config)
            .then(response => setAccessRequests(response.data))
            .catch(error => console.error("An error occurred while fetching access requests:", error));
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrorSnackBarOpen(false);
    };

    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Typography variant="h4">Access Request</Typography>
            <Paper className={classes.root}>
                {accessRequestSubmitted ? (
                    <Typography variant="h6" color="primary">Access request submitted successfully!</Typography>
                ) : (
                    <>
                        <TextField
                            label="Reason For Request"
                            variant="outlined"
                            fullWidth
                            required
                            value={reasonForRequest}
                            onChange={(e) => {
                                setReasonForRequest(e.target.value);
                                setMissingRequestReason(false);
                            }}
                            helperText={missingRequestReason && "Required"}
                            error={missingRequestReason}
                        />

                        {companies && (
                            <Autocomplete
                                style={{ marginTop: '20px', marginBottom: '10px' }}
                                multiple
                                options={companies.company}
                                getOptionLabel={(option) => option.name}
                                onChange={handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Companies"
                                        error={Boolean(missingCompany)}
                                        helperText={missingCompany}
                                    />
                                )}
                            />
                        )}

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAccessRequestSubmit}
                        >
                            Submit
                        </Button>
                    </>
                )}
            </Paper>

            <Snackbar
                open={errorSnackBarOpen}
                autoHideDuration={5000}
                onClose={handleErrorSnackbarClose}
                message={errorSnackBarText}
            />
        </Container>
    );
};
